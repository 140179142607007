<template>
  <div class="doc">
    <el-card class="box-card">
      <div slot="header">
        <div class="title fz-18">发布文档</div>
      </div>
      <el-form size="small" class="docForm" :rules="docFormConfig.rules" :model="docFormConfig.data"
               :ref="docFormConfig.formName" label-width="150px">
        <el-form-item label="文档分类" prop="docTypeValue">
          <el-cascader
            style="width: 380px;"
            v-model="docFormConfig.data.docTypeValue"
            :options="docTypeOptions"
            @change="onDocTypeCascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item class="doc-input-wrap" label="文档名称" prop="docName">
          <el-input show-word-limit clearable :maxlength="40"
                    v-model="docFormConfig.data.docName"></el-input>
        </el-form-item>
        <el-form-item label="文档格式" prop="docFormat">
          <el-select v-model="docFormConfig.data.docFormat" placeholder="请选择文档格式">
            <el-option
              v-for="item in docFormats"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文档是否加密" prop="encrypt">
          <el-radio-group v-model="docFormConfig.data.encrypt">
            <el-radio :label="0">未加密</el-radio>
            <el-radio :label="1">加密</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="docFormConfig.data.encrypt === 1">
          <el-form-item label="解密密码" prop="encryptPassword">
            <el-input v-model="docFormConfig.data.encryptPassword"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="文档是否可编辑" prop="editable">
          <el-radio-group v-model="docFormConfig.data.editable">
            <el-radio :label="1">可编辑</el-radio>
            <el-radio :label="0">不可编辑</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文档页数" prop="docPage">
          <el-input :maxlength="8" v-model.number="docFormConfig.data.docPage"></el-input>
        </el-form-item>
        <el-form-item label="文档个数" prop="docNumber">
          <el-input :maxlength="8" v-model.number="docFormConfig.data.docNumber"></el-input>
        </el-form-item>
        <el-form-item label="文档大小" prop="docSize">
          <el-input v-model="docFormConfig.data.docSize">
            <el-select style="width: 70px;" slot="append" v-model="docFormConfig.data.sizeUnit"
                       placeholder="请选择">
              <el-option
                v-for="item in sizeUnit"
                :key="item.value"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="是否包含水印" prop="includeWatermark">
          <el-radio-group v-model="docFormConfig.data.includeWatermark">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="auto" label="文档展示封面" prop="docCover">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            ref="uploadFile"
            :class="{hiddenUpload: docFormConfig.data['docCover']}"
            :data="{...uploadEnum.DOCS}"
            list-type="picture-card"
            :file-list="docFormConfig.previewImg.docCover"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'docCover'})"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'docCover'})"
            :on-remove="uploadConfig.onRemove.bind(null,  {key: 'docCover'})"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>提示：仅限jpg/png文件，文件小于1M，建议300x300</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item class="auto" label="文档展示图" prop="showImages">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :data="{...uploadEnum.DOCS}"
            :limit="5"
            ref="uploadFileShowImages"
            :class="['A4', {'hiddenUpload': docFormConfig.data.showImages && docFormConfig.data.showImages.length >= 5}]"
            :file-list="docFormConfig.data.showImages"
            :on-exceed="uploadConfig.fileExceed"
            :on-error="uploadConfig.uploadFileError"
            list-type="picture-card"
            :multiple="true"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'showImages'})"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'showImages'})"
            :on-remove="uploadConfig.onRemove.bind(null,  {key: 'showImages'})"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>提示：仅限jpg/png文件，文件小于1M，建议A4纸比例</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文档价格" prop="price">
          <el-input v-model="docFormConfig.data.price">
            <template slot="append">RMB</template>
          </el-input>
          <el-tooltip class="el-input-question" effect="dark" content="点击查看佣金比例" placement="top">
            <i @click="profitRateDialogConfig.showDialog()" class="el-icon-question"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="文档原价" prop="originPrice">
          <el-input v-model="docFormConfig.data.originPrice">
            <template slot="append">RMB</template>
          </el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input :maxlength="5" max="10000" v-model.number="docFormConfig.data.stock"></el-input>
        </el-form-item>
        <el-form-item class="auto" label="交易形式" prop="transactionForm">
          <el-radio-group v-model="docFormConfig.data.transactionForm">
            <el-radio :label="1">私下交易</el-radio>
            <el-radio :label="2">担保交易</el-radio>
            <el-radio :label="3">两者皆可</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="auto" label="支持发货形式" prop="deliveryForm">
          <el-checkbox-group v-model="docFormConfig.data.deliveryForm">
            <el-checkbox label="2">(自动)网盘下载</el-checkbox>
            <el-checkbox label="3">(自动)网站直接下载</el-checkbox>
            <el-checkbox label="1">(人工)邮件发送</el-checkbox>
            <el-checkbox label="4">(手动，需要有实物)物流邮寄</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <template v-if="docFormConfig.data.deliveryForm.includes('2')">
          <el-form-item label="网盘类型" prop="netdiscName"
                        :rules="{required: true, message: '请选择网盘类型', trigger: ['blur', 'change']}">
            <el-select v-model="docFormConfig.data.netdiscName" placeholder="选择网盘类型">
              <el-option
                v-for="item in netdiscNames"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网盘地址" prop="netdiscUrl">
            <el-input v-model="docFormConfig.data.netdiscUrl" @blur="blur1"></el-input>
          </el-form-item>
          <el-form-item label="网盘密码">
            <el-input v-model="docFormConfig.data.netdiscPwd"></el-input>
          </el-form-item>
          <el-form-item label="解压密码">
            <el-input v-model="docFormConfig.data.netdiscUnzipPwd"></el-input>
          </el-form-item>
        </template>
        <template v-if="docFormConfig.data.deliveryForm.includes('3')">
          <el-form-item class="auto" label="文档" prop="docContent">
            <el-upload
              :http-request="fileUploadFunction"
              action=""
              :data="{...uploadEnum.DOCS}"
              drag
              ref="uploadFileDocContent"
              :file-list="docFormConfig.previewImg.docContent"
              :on-error="uploadConfig.uploadFileError"
              :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'docContent'})"
              :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'docContent'})"
              :on-remove="uploadConfig.onRemove.bind(null,  {key: 'docContent'})"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">
                <div>提示：限制文件最大200M</div>
              </div>
            </el-upload>
          </el-form-item>
        </template>
        <el-form-item class="auto" label="文档介绍" prop="introduction">
          <ckeditor v-model="docFormConfig.data.introduction"></ckeditor>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="docFormConfig.onTemporaryStorageClick">暂存</el-button>
          <el-button size="small" @click="docFormConfig.onSubmitClick()" type="primary">发布
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      center
      title="佣金比例"
      :visible.sync="profitRateDialogConfig.showFlag"
    >
      <profit-rate></profit-rate>
    </el-dialog>
  </div>
</template>

<script>
import validator from '../../../../resources/js/async-validator';
import {doc} from "../../../../apis";
import uploadEnum from "@/resources/js/uploadenum";

const FileType = require('file-type');
export default {
  name: "edit",
  props: ['id'],
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
    ProfitRate: () => import('@PAGE/common/ProfitRate'),
  },
  data() {
    return {
      uploadEnum,
      profitRateDialogConfig: {
        showFlag: false,
        showDialog: () => {
          this.profitRateDialogConfig.showFlag = true;
        },
      },
      docFormConfig: {
        formName: 'docForm',
        data: {
          sizeUnit: 'KB',
          docTypeValue: [],
          // codeType: null,
          docType: null,
          docSubType: null,
          docName: null,
          docFormat: null,
          encrypt: 0,
          encryptPassword: null,
          editable: null,
          docPage: null,
          docNumber: null,
          docSize: null,
          includeWatermark: null,
          docCover: null,
          showImages: [],
          price: null,
          originPrice: null,
          stock: null,
          transactionForm: null,
          deliveryForm: [],
          introduction: null,
          netdiscName: null,
          netdiscUrl: null,
          netdiscPwd: null,
          netdiscUnzipPwd: null,
          docContent: null,
        },
        previewImg: {
          docCover: [],
          showImages: [],
        },
        rules: {
          netdiscUrl: [
            {required: true, message: '请输入网盘地址', trigger: ['change', 'blur']},
          ],
          // codeType: [
          //   {required: true, message: '请选择文档类型', trigger: ['change', 'blur']},
          // ],
          docTypeValue: [
            {required: true, message: '请选择文档分类', trigger: ['change', 'blur']},
          ],
          docName: [
            {required: true, message: '请输入文档名称', trigger: ['change', 'blur']},
          ],
          docFormat: [
            {required: true, message: '请选择文档格式', trigger: ['change', 'blur']},
          ],
          encrypt: [
            {required: true, message: '请选择是否加密', trigger: ['change', 'blur']},
          ],
          encryptPassword: [
            {required: true, message: '请输入解密密码', trigger: ['change', 'blur']},
          ],
          editable: [
            {required: true, message: '请选择是否可编辑', trigger: ['change', 'blur']},
          ],
          docPage: [
            {required: true, message: '请输入文档页数', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validInteger(rule, value, callback);
                }
              },
              message: '数值不合法，只能输入整数'
            },
            {
              validator: (rule, value, callback) => {
                if (value<0) {
                  callback(new Error("数值不合法，只能输入正整数"));
                }else{
                  callback()
                }
              }
            }
          ],
          docNumber: [
            {required: true, message: '请输入文档个数', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validInteger(rule, value, callback);
                }
              },
              message: '数值不合法，只能输入整数'
            },
            {
              validator: (rule, value, callback) => {
                if (value<0) {
                  callback(new Error("数值不合法，只能输入正整数"));
                }else{
                  callback()
                }
              }
            }
          ],
          docSize: [
            {required: true, message: '请输入文档大小', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validNum(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                if (value<0) {
                  callback(new Error("数值不合法，只能输入正整数"));
                }else{
                  callback()
                }
              }
            }
          ],
          includeWatermark: [
            {required: true, message: '请选择是否包含水印', trigger: ['change', 'blur']},
          ],
          docCover: [
            {required: true, message: '请上传文档封面', trigger: ['change', 'blur']},
          ],
          showImages: [
            {required: true, message: '请上传展示图', trigger: ['change', 'blur']},
          ],
          price: [
            {required: true, message: '请输入价格', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validMoney(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {originPrice: realPrice} = this.docFormConfig.data;
                realPrice = parseFloat(realPrice);
                value = parseFloat(value);
                if ((realPrice || realPrice === 0) && realPrice < value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '售价不能大于原价'
            },
          ],
          originPrice: [
            {required: true, message: '请输入原价', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validMoney(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {price: salePrice} = this.docFormConfig.data;
                salePrice = parseFloat(salePrice);
                value = parseFloat(value);
                if (value && salePrice && salePrice > value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '原价不能小于售价'
            },
          ],
          stock: [
            {required: true, message: '请输入库存', trigger: ['change', 'blur']},
            {
              validator: validator.validInteger,
              message: '数值不合法',
              trigger: ['change', 'blur']
              },
              {
                validator: (rule, value, callback) => {
                  if (value > 10000) {
                    callback('最大10000');
                  } else if (value <= 0) {
                    callback('库存不能小于1');
                  } else {
                    callback();
                  }
                },
                trigger: ['change', 'blur']
              },
          ],
          docContent: [
            {required: true, message: '请上传文档', trigger: ['change', 'blur']},
          ],
          transactionForm: [
            {required: true, message: '请选择交易形式', trigger: ['change', 'blur']},
          ],
          deliveryForm: [
            {required: true, message: '请选择发货形式', trigger: ['change', 'blur']},
          ],
          introduction: [
            {required: true, message: '请输入文档介绍', trigger: ['change', 'blur']},
          ],
        },
        condition: {},
        loadCondition: () => {
          doc.loadCondition().then(res => {
            let {data} = res;
            this.docFormConfig.condition = data;
          });
        },
        loadDetail: () => {
          doc.editdetail({docId: this.id, userId: this.user.userId}).then(res => {
            let {data} = res;
            let {data: form} = this.docFormConfig;
            form.sizeUnit = data.sizeunit;
            form.docType = data.doctype;
            form.docSubType = data.docsubtype;
            form.docTypeValue = [data.doctype, data.docsubtype];
            form.docName = data.docname;
            form.docFormat = data.docext;
            form.encrypt = data.encryptflag;
            form.encryptPassword = data.encryptPwd;
            form.editable = data.editflag;
            form.docPage = data.docpage;
            form.docNumber = data.docamount;
            form.docSize = data.docsize;
            form.includeWatermark = data.watermarkflag;
            form.docCover = data.doccoverImgurl;
            form.showImages = data.showimgs.map(item => {
              return {
                url: item,
                response: {
                  src: item,
                },
              }
            })
            form.price = data.saleprice;
            form.originPrice = data.realprice;
            form.stock = data.stock;
            form.transactionForm = data.dealmodel;
            form.deliveryForm = data.saletype.split(',');
            form.introduction = data.introduce;
            form.netdiscName = data.netdiscname;
            form.netdiscUnzipPwd = data.netdiscunzippwd;
            form.netdiscUrl = data.netdiscurl;
            form.netdiscPwd = data.netdiscpwd;
            form.docContent = data.codepath;
            // form.codeType = data.codetype;
            this.docFormConfig.previewImg.docCover = [{
              url: data.doccoverImgurl,
            }];
            if (data.codepath) {
              this.docFormConfig.previewImg.docContent = [{
                url: data.codepath,
                name: data.codepath.substring(data.codepath.lastIndexOf('/') + 1,
                  data.codepath.length),
              }];
            }
            this.docFormConfig.data = form;
          });
        },
        onSubmitClick: () => {
          this.$refs[this.docFormConfig.formName].validate().then(res => {
            console.log(1)
            this.docFormConfig.doSubmitForm();
          }).catch((err) => {
            console.log(err);
            this.$message.warning('请完成表单')
          });
        },
        onTemporaryStorageClick: () => {
          const {data: formData, rules, formName} = this.docFormConfig;
          let checkField = [];
          Object.keys(formData).forEach(key => {
            let value = formData[key];
            let needCheck = false;

            if (Array.isArray(value)) {
              if (value.length > 0) {
                needCheck = true;
              }
            } else if (value) {
              needCheck = true;
            }
            if (needCheck && rules[key]) {
              checkField.push(key);
              console.log(checkField)
            }
          })
          if (checkField.length === 0) {

            this.docFormConfig.doSubmitForm(true);
          } else {
            let hasError;
            let counter = 0;
            this.$refs[formName].validateField(checkField, (errorMessage => {
              ++counter;
              if (errorMessage) {
                if (!hasError) {
                  hasError = true;
                  this.$message.warning(errorMessage);
                }
              } else if (!hasError && counter === checkField.length) {
                this.docFormConfig.doSubmitForm(true);
              }
            }));
          }
        },
        doSubmitForm: (temp) => {

          console.log(1)
          let {data} = this.docFormConfig;
          let id = this.id;
          doc.persist({
            docId: id,
            // codeType: data.codeType,
            docType: data.docType,
            docSubType: data.docSubType,
            docName: data.docName,
            docFormat: data.docFormat,
            encrypt: data.encrypt,
            editable: data.editable,
            docPage: data.docPage,
            docNumber: data.docNumber,
            docSize: data.docSize,
            includeWatermark: data.includeWatermark,
            docCover: data.docCover,
            showImages: data.showImages.map(item => item.response && item.response.src)
            .map(item => item.substring(item.indexOf('/upload/'))).join(','),
            price: data.price,
            originPrice: data.originPrice,
            stock: data.stock,
            transactionForm: data.transactionForm,
            deliveryForm: data.deliveryForm,
            introduction: data.introduction,
            userId: this.user.userId,
            sizeUnit: data.sizeUnit,
            encryptPassword: data.encryptPassword,
            netdiscName: data.netdiscName,
            netdiscPwd: data.netdiscPwd,
            netdiscUrl: data.netdiscUrl,
            netdiscUnzipPwd: data.netdiscUnzipPwd,
            docPath: data.docContent && data.docContent.substring(
              data.docContent.indexOf('/upload/')),
            temporary_storage: temp,
          }).then(res => {
            console.log("1111111111111")
            this.$message.success('提交成功！');
            this.goPage({
              path: '/shop/sale/techdocs'
            })
          }).catch(res => {
            this.$message.error(res.retDesc)
          })
        }
      },
      uploadConfig: {
        uploadSuccess: ({key}, response, file, fileList) => {
        if (response.code){
          if (key === 'docCover') {
            this.docFormConfig.data[key] = response.src;
            if (fileList.length > 1) {
              fileList.splice(0, 1);
            }
            this.$refs[this.docFormConfig.formName].validateField([key]);
          } else if (key === 'showImages') {
            this.docFormConfig.data[key] = fileList;
            this.$refs[this.docFormConfig.formName].validateField([key]);
          } else if (key === 'docContent') {
            this.docFormConfig.data[key] = response.src;
            if (fileList.length > 1) {
              fileList.splice(0, 1);
            }
          }
          this.$message.success('文件上传成功！');
        }else {
          let uid = file.uid
          if (key === 'docCover') {
            let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFile.uploadFiles.splice(idx, 1)
          }else if (key === 'showImages') {
            let idx = this.$refs.uploadFileShowImages.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFileShowImages.uploadFiles.splice(idx, 1)
          }else if (key === 'docContent') {
            let idx = this.$refs.uploadFileDocContent.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFileDocContent.uploadFiles.splice(idx, 1)
          }
          this.$message.error('上传文件失败')
        }
        },
        onRemove: ({key}, file, fileList) => {
          if (key === 'docCover') {
            this.docFormConfig.data[key] = null;
            fileList.splice(0, fileList.length);
          } else if (key === 'showImages') {
            this.docFormConfig.data[key] = fileList;
          } else if (key === 'docContent') {
            this.docFormConfig.data[key] = null;
            fileList.splice(0, fileList.length);
          }
        },
        beforeUpload: ({key}, file) => {
          // let fileReader = new FileReader();
          // fileReader.onload = function (event) {
          //   console.log(event)
          //   FileType.fromBuffer(event.target.result).then(res => {
          //     console.log(res);
          //   })
          // };
          // fileReader.readAsArrayBuffer(file);
          let result = false;
          if (key === 'docCover' || key === 'showImages') {
            const allowType = [
              'image/png',
              'image/jpeg'
            ];
            const isMatchType =
              allowType.some(type => file.type.startsWith(type))
            ;
            const isLtSize = file.size / 1024 / 1024 < 0.5;
            if (!isMatchType) {
              this.$message.error('上传文件只能是 图片 格式!');
              return false;
            }
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 1M!`);
              return false;
            }
            result = isMatchType && isLtSize;
          } else if (key === 'docContent') {
            const isLtSize = file.size / 1024 / 1024 < 200;
            let fileTypes = this.docFormConfig.condition.docTypes;
            let fileType = this.docFormConfig.data.docFormat;
            let fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1).toUpperCase();
            if (!fileType) {
              this.$message.error(`请先选择文档格式`);
              return false;
            }
            let fileTypeItem = (fileTypes.filter(type => type.typename === fileType)[0] || null);
            if (!fileTypeItem) {
              this.$message.error(`无法确认文件格式`);
              return false;
            }
            let targetType = fileTypeItem.mimetype.toUpperCase();
            const isMatchType =
              fileSuffix === targetType
              || targetType.includes(fileSuffix);
            if (!isMatchType) {
              this.$alert(`上传文件格式(${fileSuffix})和所选文件格式(${fileTypeItem.typename})不匹配`, '提示');
              return false;
            }
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 200MB!`);
              return false;
            }
            result = isLtSize && isMatchType;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  watch: {
    'docFormConfig.data.price': {
      handler(val) {
        let {originPrice: realPrice} = this.docFormConfig.data;
        if (realPrice) {
          this.$refs[this.docFormConfig.formName].validateField(['price', 'originPrice']);
        }
      },
    },
    'docFormConfig.data.originPrice': {
      handler(val) {
        let {price: salePrice} = this.docFormConfig.data;
        if (salePrice) {
          this.$refs[this.docFormConfig.formName].validateField(['price', 'originPrice']);
        }
      },
    },
    'docFormConfig.data.introduction': {
      handler(val) {
        this.$refs[this.docFormConfig.formName].validateField(['introduction']);
      },
    },
  },
  methods: {
    onDocTypeCascaderChange(value) {
      this.docFormConfig.data.docType = value[0];
      this.docFormConfig.data.docSubType = value[1];
    },
    blur1(){
      if(!(/^[hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/.test(this.docFormConfig.data.netdiscUrl)))
        {
          this.$alert('网盘地址错误，http或https开头加域名地址');
          this.docFormConfig.data.netdiscUrl=null;
        }
    }
  },
  beforeMount() {
    const {category} = this.getQuery();
    if (!this.id && !category) {
      this.$router.push('/shop/release/techdocs');
      return
    }
    if (category) {
      this.docFormConfig.data.docTypeValue = [category[0].typeId, category[1].typeId];
      this.docFormConfig.data.docType = category[0].typeId;
      this.docFormConfig.data.docSubType = category[1].typeId;
    }
    this.docFormConfig.loadCondition();
    if (this.id) {
      this.docFormConfig.loadDetail();
    }
  },
  computed: {
    docTypeOptions() {
      let options = [];
      let types = this.docFormConfig.condition.types;
      if (types && types.length > 0) {
        options = types.map(type => {
          return {
            label: type.typeName,
            value: type.typeId,
            children: type.children.map(item => {
              return {
                label: item.typeName,
                value: item.typeId,
              }
            })
          }
        })
      }
      return options;
    },
    netdiscNames() {
      let options = [];
      const names = this.docFormConfig.condition.netdiscName;
      if (names && names.length > 0) {
        options = names.map(item => {
          return {
            value: item.dropdownvalue,
            label: item.dropdownvalue
          }
        })
      }
      return options;
    },
    docFormats() {
      let options = [];
      if (this.docFormConfig.condition.docTypes) {
        const type = this.docFormConfig.condition.docTypes;
        options = type.map(item => {
          return {
            // value: item.id,
            value: item.typename,
            label: item.typename
          }
        })
      }
      return options;
    },
    sizeUnit() {
      return [
        {
          label: 'KB',
          value: 1
        },
        {
          label: 'M',
          value: 1024
        }
      ]
    },
  }
}
</script>

<style scoped lang="scss">
.doc {
  /deep/ {
    .el-card__header .title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 3px;
        background: $main-color;
        top: 0;
        bottom: 0;
        left: -10px;
      }
    }

    .el-form-item {
      .el-input-question {
        position: absolute;
        right: -25px;
        top: 14px;
        cursor: pointer;
      }
    }

    .doc-input-wrap {
      width: 530px !important;

      input {
        padding-right: 80px;
      }
    }
  }

  .docForm {
    & /deep/ .el-form-item:not(.auto) {
      width: 400px;
    }

    /deep/ img {
      object-fit: contain;
    }

    .A4 {
      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        height: 210px;
        line-height: 210px;
      }

      /deep/ .el-upload--picture-card {
        height: 210px;
        line-height: 210px;
      }
    }
  }
}
</style>
